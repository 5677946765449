import { defineStore } from 'pinia'
import { apiGet, apiPost } from '~/services/api'
import { PopUpStore } from '~/store/popUp'
import { getCoordsByString } from '~/helpers/Map/getCoordsByString'

export const useFormStore = defineStore('form', {
  state: () => ({
    cookieAgrement: false,
    first_name: '',
    last_name: '',
    middle_name: '',
    phone: '',
    city: '',
    cityCoords: [] as number[],
    contacts: { tg: '', wa: '', inst: '', vib: '', email: '', extraPhone: '' },
    isTGbotSet: false,
    notifications: [
      {
        name: 'Клиент посмотрел презентацию',
        id: 1,
        value: false,
      },
      {
        name: 'Клиент записался на просмотр объекта',
        id: 1,
        value: false,
      },
      {
        name: 'Найден подходящий объект',
        id: 1,
        value: false,
      },
      {
        name: 'Найден покупатель на ваш объект',
        id: 1,
        value: false,
      },
      {
        name: 'Напоминания о забытых клиентах',
        id: 1,
        value: false,
      },
      {
        name: 'Уведомление об оплате',
        id: 1,
        value: false,
      },
    ],
    isVizitka: false,
    isPrivacy: false,
    employment_status: [] as string[],
    directions: [] as string[],
    about_myself: '',
    userAvatarLink: undefined,
    loaderProfilePage: true,
    loaderAwadrsPage: true,
    ImgloaderVisible: true,
    userSurveyBackTrigger: [],
    userSurveyFrontTrigger: null,
    loginInputError: false,
    winH: 0,
    winW: 0,
  }),
  actions: {
    setScreenSizeInCSS() {
      this.winW = window.innerWidth
      this.winH = window.innerHeight
      document.documentElement.style.setProperty('--screen-width', `${this.winW}px`)
      document.documentElement.style.setProperty('--screen-height', `${this.winH}px`)
    },
    async fetchUserData() {
      try {
        const { data } = await apiGet({ url: 'api/users/profile' })
        if (data) {
          this.setUserData(data)
          this.cityCoords = await getCoordsByString(data.value.data.city)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loaderProfilePage = false
      }
    },
    async setUserData(data: any) {
      if (!data) return
      this.first_name = data.value.data.first_name
      this.last_name = data.value.data.last_name
      this.middle_name = data.value.data.middle_name
      this.phone = data.value.data.phone
      this.city = data.value.data.city
      this.employment_status = [data.value.data.employment_status]
      this.directions = data.value.data.directions
      this.about_myself = data.value.data.about_myself
      this.contacts.email = data.value.data.email
      this.contacts.tg = data.value.data.telegram
      this.contacts.wa = data.value.data.whatsapp
      this.contacts.vib = data.value.data.viber
      this.contacts.inst = data.value.data.instagram
      this.contacts.extraPhone = data.value.data.additional_phone
    },
    async postUserData() {
      const form = {
        first_name: this.first_name,
        last_name: this.last_name,
        middle_name: this.middle_name,
        phone: this.phone,
        city: this.city,
        employment_status: this.employment_status[0],
        about_myself: this.about_myself,
        directions: this.directions,
        email: this.contacts.email,
        telegram: this.contacts.tg,
        whatsapp: this.contacts.wa,
        viber: this.contacts.vib,
        instagram: this.contacts.inst,
        additional_phone: this.contacts.extraPhone,
      }
      try {
        const { data } = await apiPost({ url: 'api/users/profile', body: { ...form } })
        if (data) {
          ElNotification({
            type: 'success',
            title: 'Успешно',
            message: 'Данные пользователя изменены',
          })
        }
      } catch (e) {
        console.log(e)
      }
    },

    async fetchUserAvatar() {
      try {
        const { data } = await apiGet({ url: 'api/users/profile/avatar' })
        if (data) {
          this.userAvatarLink = (data as any).value.url
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.ImgloaderVisible = false
      }
    },
    async fetchSurvey() {
      try {
        const { data } = await apiGet({ url: 'api/question/questions-for-user' })
        if (data) {
          this.userSurveyBackTrigger = (data as any).value.backendTriggerQuestions
          this.userSurveyFrontTrigger = (data as any).value.userSurveyFrontTrigger
        }
      } catch (e) {
        console.log(e)
      } finally {
        if (this.userSurveyBackTrigger?.length != 0) {
          const PopUpControl = PopUpStore()
          PopUpControl.callPopUp('AfterRegistration')
        }
        this.ImgloaderVisible = false
      }
    },
  },
})
